import { registerApplication, start } from "single-spa";

const root = "/ext/door-planner/";
//const root = "/";
registerApplication({
  name: "@kcs/door-planner",
  app: () => System.import("@kcs/door-planner"),
  activeWhen: root,
});

registerApplication({
  name: "@kcs/drilling-manager",
  app: () => System.import("@kcs/drilling-manager"),
  activeWhen: `${root}advance-drilling-manager`,
});

start();
